.homepage-block-blue {
    &__cta-text {
        ul {
            display: block;
            list-style-type: disc;
            margin-block-start: 1em;
            margin-block-end: 1em;
            margin-inline-start: 0px;
            margin-inline-end: 0px;
            padding-inline-start: 40px;

            li {
                margin-bottom: 5px;
                font-size: 18px;
                line-height: 22px;
                text-align: left;
            }
        }
    }
}
