.content {
    ul {
        display: block;
        list-style-type: disc;
        // margin-block-start: 1em;
        // margin-block-end: 1em;
        // margin-inline-start: 0px;
        // margin-inline-end: 0px;
        // padding-inline-start: 40px;

        li {
            // margin-bottom: 7px;
            // font-size: 1.3vw;
            // line-height: 1.7vw;
            // text-align: left;
        }
    }
}
